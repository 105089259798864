import { IRequestParams } from '@/types';

export const ACTIVITIES_PAGINATION_LIMIT = 10;

export const activitiesParameters = (offset: number, order?: Array<string[]>): IRequestParams => ({
  params: {
    filter: {
      limit: ACTIVITIES_PAGINATION_LIMIT,
      offset,
      order: order?.length ? order : [ ["createdAt", "DESC"] ],
      where: {
        status: 'active',
        endDate: { $gt: new Date().toISOString() }
      },
      include: [
        {
          model: "category",
          attributes: ["id", "name"]
        }
      ]
    },
    includeCount: true
  }
});

export const ACTIVITY_PARAMS: IRequestParams = {
  params: {
    filter: {
      include: [
        { model: "category" },
        { association: "attendees" }
      ]
    }
  }
};

export const CATEGORIES: IRequestParams = {
  params: {
    filter: {
      where: {
        status: 'active'
      },
    }
  }
};