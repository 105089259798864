
  import { defineComponent, onBeforeMount, reactive, toRefs, computed, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { activitiesService } from '@/services';
  import { IActivity, IActivityResponse, ICategory } from '@/types';
  import { activitiesParameters, ACTIVITIES_PAGINATION_LIMIT } from '@/views/admin/activities/api-params';
  import { downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'ActivitiesList',

    components: { AppButton, AppIcon, AppTable, AppModal, PaginationCounter },

    setup() {
      const toast = useToast();
      const state = reactive({ 
        activitiesList: [] as IActivity[], 
        sortListBy: [] as Array<string[]>, 
        activitiesTotal: 0 as number
      });
      let cancelActivityId = ref<number | string>('');
      let isOpenConfirmCancelModal = ref<boolean>(false);
      const { activitiesList, activitiesTotal } = toRefs(state);
      const currentPage = ref<number>(0);

      const tableHeaders = computed(() => [
        { property: 'name', label: 'Activity', sortable: true, minWidth: 200 },
        { property: 'category', label: 'Category', sortable: true, minWidth: 180 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 }
      ]);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleUpdate, customClass: ' text-dark-fp-20' },
        { title: 'Cancel', icon: 'trash-alt', handler: openConfirmationModal, customClass: ' text-error' }
      ]);

      const offset = computed<number>(() => {
        return currentPage.value * ACTIVITIES_PAGINATION_LIMIT;
      });

      function openConfirmationModal({ id }: IActivity, handleHideTooltipCb: any) {
        isOpenConfirmCancelModal.value = true;
        cancelActivityId.value = id as number;
        handleHideTooltipCb();
      }

      function handleUpdate({ id }: IActivity) {
        router.push({ name: routesNames.adminUpdateActivities, query: { id }});
      }

      function discardCancel() {
        closeConfiramationModal();
        cancelActivityId.value = '';
      }

      function mapActivityList(goals: IActivity[]) {
        activitiesList.value = goals.map((el: IActivity) => {
          return {
            ...el,
            category: (el.category as ICategory ).name
          };
        });
      }

      function onCurrentPageChanged(page: number) {
        currentPage.value = page - 1;

        fetchActivities();
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          if (orderBy === 'category') {
            state.sortListBy = [['category', 'name', order]];
          } else {
            state.sortListBy = [[orderBy, order]];
          }
        } else {
          state.sortListBy = [];
        }

        fetchActivities();
      }

      async function fetchActivities() {
        vuex.setLoading(true);

        return await activitiesService.fetchActivities(activitiesParameters(offset.value, state.sortListBy))
          .then((res: IActivityResponse) => { 
            mapActivityList(res.data); 
            activitiesTotal.value = res.totalCount;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      function closeConfiramationModal() { 
        isOpenConfirmCancelModal.value = false; 
      }

      async function confirmCancellation() {
        closeConfiramationModal();
        vuex.setLoading(true);

        return activitiesService.removeActivity(cancelActivityId.value as number)
          .then(() => {
            toast.success('Activity has been canceled');
            if (activitiesList.value.length === 1) {
              currentPage.value = 0;
            }
            return fetchActivities();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return activitiesService.downloadActivities(state.sortListBy)
          .then((res: any) => {
            downloadHelper(res, 'Activities', 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => {
        fetchActivities();
      });

      return {
        routesNames,
        router,
        activitiesList,
        tableHeaders,
        actions,
        isOpenConfirmCancelModal,
        activitiesTotal,
        offset,
        ACTIVITIES_PAGINATION_LIMIT,

        discardCancel,
        confirmCancellation,
        onExport,
        handleSort,
        onCurrentPageChanged
      };
    }

  });
